import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SessionRepository } from '../../state/session/session.repository';
import { SyncRepository } from '../../state/sync/sync.repository';

@Injectable({
  providedIn: 'root',
})
export class NoAuthGuard {
  constructor(
    public router: Router,
    private sessionRepository: SessionRepository,
    private syncRepository: SyncRepository
  ) {}

  canActivate() {
    return this.isNotLoggedIn();
  }

  isNotLoggedIn() {
    if (this.sessionRepository.isLoggedIn()) {
      this.router.navigate(['/packages']);
      return false;
    }

    this.syncRepository.setAirplaneModeEnabled(false); // make sure we don't lock the user out of the app
    return true;
  }
}

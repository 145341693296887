import { Injectable } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import {
  getActiveEntity,
  resetActiveId,
  selectActiveEntity,
  selectAllEntities,
  setActiveId,
  setEntities,
  updateEntities,
  withActiveId,
  withEntities,
} from '@ngneat/elf-entities';
import { sessionStorageStrategy, localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { map, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Appointment } from '../../packages/state/packages.interface';
import { AppointmentDateItem } from './appointment-dates.interface';

interface AppointmentDates {
  hasToday: boolean;
}

const initialState = {
  hasToday: null,
};

const store = createStore(
  { name: 'appointment-dates' },
  withProps<AppointmentDates>(initialState),
  withEntities<AppointmentDateItem>(),
  withActiveId()
);

export let persist;

@Injectable({ providedIn: 'root' })
export class AppointmentDatesRepository {
  activeDate$ = store.pipe(selectActiveEntity());
  appointments$ = store.pipe(selectAllEntities());
  hasToday$ = store.pipe(select((state) => state.hasToday));

  constructor() {
    const strategy = environment.production ? sessionStorageStrategy : localStorageStrategy;

    persist = persistState(store, {
      key: 'appointment-dates',
      storage: strategy,
    });
  }

  getActiveDateId() {
    return store.value.activeId;
  }

  setActiveDate(date: string) {
    store.update(setActiveId(date));
  }

  resetActiveDate() {
    store.update(resetActiveId());
  }

  setDates(dates: AppointmentDateItem[]) {
    store.update(setEntities(dates));
  }

  setTodayStatus(hasToday: boolean) {
    store.update((state) => ({ ...state, hasToday }));
  }

  getAppointmentByUser(appointmentId: Appointment['appointment_id']): Appointment {
    const { appointments } = store.query(getActiveEntity());

    const user = appointments.find((appointment) => appointment.appointment_id === appointmentId);

    return user;
  }

  getAppointmentByCheckupId(checkupId: string | number) {
    const { appointments } = store.query(getActiveEntity());

    const appointment = appointments?.find((item) => +item?.checkup?.checkup_id === +checkupId);

    return appointment;
  }

  searchUsers(term): Observable<Appointment[]> {
    const caseInsensitive = new RegExp(term, 'i');

    return store.pipe(
      selectActiveEntity(),
      map((appointmentDates) => {
        if (appointmentDates === undefined || appointmentDates === null) {
          return [];
        }
        const { appointments } = appointmentDates;
        return appointments.filter(
          (appointment) =>
            caseInsensitive.test(appointment.checkup.user.wellabe_id) ||
            caseInsensitive.test(appointment.checkup.user.email) ||
            caseInsensitive.test(appointment.checkup.user.name) ||
            appointment.checkup.user_id == term ||
            appointment.checkup.checkup_id == term
        );
      })
    );
  }

  updateActiveDateAppointment(appointmentId: number, partial: Partial<Appointment>) {
    store.update(
      updateEntities(store.value.activeId, (entity) => {
        const appointments = [...entity.appointments];
        const index = appointments.findIndex((appointment) => appointment.appointment_id === appointmentId);

        if (index < 0) {
          return entity;
        }

        if (!partial.tag) {
          partial.tag = undefined;
        }

        appointments[index] = { ...appointments[index], ...partial };

        return { ...entity, appointments };
      })
    );
  }

  cleanActiveDateAppointmentTags() {
    store.update(
      updateEntities(store.value.activeId, (entity) => {
        const appointments = entity.appointments.map((appointment) => {
          appointment.tag = undefined;
          return appointment;
        });

        return { ...entity, appointments };
      })
    );
  }
}
